import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import Image2 from '../components/image-2'
import Image3 from '../components/image-3'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div class="banner">
      <div class="container">
        <div class="colx2">
          <div class="copy">
            <h3>Cravv<sup>®</sup>: The Natural way to quit smoking</h3>
            <p>Each bottle contains the full 25 day course to help you quit!</p>
            <p class="subline">available in canada only</p>
            <a class="btn" href="https://zpharm.myshopify.com/cart/25385746313:1?channel=buy_button">Order Now</a>
            <p class="subline">**This product may not be right for you. Always read and follow the label.</p>
          </div>
        </div>
        <div class="colx2">
          <Image />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="colx1">
          <h3 class="left">
            Cravv<sup>®</sup> is a natural health product for smoking cessation. It is effective for smoking cessation and costs less than many traditional smoking cessation products.
          </h3>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="colx2">
          <div class="content">
            <h3>Supported By Science</h3>
            <p>
              We are constantly surveying the market to find opportunities for novel medications in areas where patient needs are unmet.
            </p>
            <a class="btn_alt" href="science/">Read the Research <i className="fa fa-angle-right"></i></a>        
          </div>
        </div>
        <div class="colx2">
        <Image2 />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="colx2">
          <div class="content">
            <h3>Customers Are Raving</h3>
            <p>
              We are constantly surveying the market to find opportunities for novel medications in areas where patient needs are unmet.
            </p>
            <a class="btn_alt" href="reviews/">Read the Reviews <i className="fa fa-angle-right"></i></a>        
          </div>
        </div>
        <div class="colx2">
          <Image3 />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="colx2">
          <h3>Contact Us</h3>
          <p>
            General Inquiries: <a href="mailto:info@zpharm.ca">info@zpharm.ca</a>
          </p>
          <br/>
          <p>
            Career Inquiries: <a href="mailto:work@zpharm.ca">work@zpharm.ca</a>
          </p>
        </div>
        <div class="colx2">
          <h3>Location</h3>
          <p>
            Waterloo, Ontario, Canada
          </p>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="colx1">
          <p class="subline">
          Individual results may vary. These products are to be used with willpower in order to control cigarette cravings as part of a smoking cessation program. Please note this content is intended solely as a general information aid about our products and does not constitute a diagnosis of any medical condition or medical advice, including advice about the treatment of any medical condition. This site is not intended as a substitute for professional healthcare advice and services from a qualified healthcare provide or other professional familiar with your unique situation. This content is intended solely as a general product and educational aid. If you have any questions or concerns, please consult your doctor or pharmacist.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
